@import url(https://fonts.googleapis.com/css?family=Playfair+Display:900);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);

.title-3 {
  margin: 10px;
  font-family: sans-serif;
}

.logo-btn1 {
  width: 300px;
}

.logo-icon {
  width: 22px; /* Set width to 25px */
  height: 22px; /* Set height to 25px */
  display: inline-block; /* Ensure the image doesn't break to a new line */
  vertical-align: middle; /* Center vertically within the line */
  margin-right: 10px; /* Add some space between the logo and text */
}

.login-table {
  display: inline-grid; /* Use grid layout */
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 10px;
  gap: 10px; /* Space between items */
  width: 600px;
  margin: 10px;
  font-family: sans-serif;
}

.login-option {
  display: flex; /* Use flex for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 10px;
  border: 1px solid gray; /* Optional: Add border for visibility */

  box-sizing: border-box;

  cursor: pointer;
  /* background-color: orange; */
}


.ham-icon {
  display: none !important;
}

.absolute-pass {
  display: flex;
  align-items: center;
  justify-content: left;

  position: absolute;
  bottom: 15px;
  right: 10px;

  color: white;

  border: 0px solid black;


  height: 10px !important;
  padding: 10px !important;
  /* background-color: dimgray; */
  color: white;
  border: 0px solid red;

}
.absolute-pass-ico {
  background-color: #0096FF;
  cursor: pointer;
  padding: 7px;
  border-radius: 20px;
}

.absolute-pass-ico-ok {
  background-color: greenyellow;
  cursor: pointer;
  padding: 7px;
  border-radius: 20px;
  color: black;
}

.absolute-pass-inp {
  border-radius: 5px;
  background-color: #0096FF;
  border: 1px solid #0096FF;
  overflow: hidden;
  width: 160px;
  padding: 5px;
  white-space: nowrap;
  margin-right: 15px;
  margin-left: 15px;
  min-height: 18px; /* added only to avoid minimization for disabled input, when message is already decrypted ! */
}

.absolute-pass-inp-ok {
  border: 2px solid greenyellow !important;
}

.absolute-pass-inp:focus {
    outline: none;
}

@media only screen and (max-width: 768px) {
  .menu-large {
    display: none !important;
  }

  .menu-icon {
    display: table-cell !important;
  }

  .ham-icon {
    display: table-cell !important;
  }

  .login-table {
    display: inline-grid; /* Use grid layout */
    grid-template-columns: repeat(1, 1fr); /* 2 columns */
    grid-gap: 10px;
    gap: 10px; /* Space between items */
    width: 300px;
    margin: 10px;
    font-family: sans-serif;
  }
}


/* make sure this div has no impact on layat, its only to have a parent that supports keyDown event !!!! */
.keyDownDiv {
  display: inline;
}

.hamburgerMenu {
  position: absolute;
  overflow: hidden;

  /* background: -webkit-linear-gradient(to right, #4b134f, #c94b4b);
  background: linear-gradient(to right, #4b134f, #c94b4b);  */
  /* z-index: 1; */
  /* transform: translateX(110%);
  transition: transform 0.5s ease; */
  padding-left: 15px;
  padding-top: 15px;
  top: 53px;
  left: 0px;
  height: 100%;
  width: 100%;
  
}

.hamburgerMenu div {
  border: 0px solid white;
  padding-bottom: 10px;
  cursor: pointer;
}

html, body {
  margin:0px;
  height:100%;
  font-family: "Roboto Mono", monospace;
  font-size: 16px;

  /* added that only to hide scroll bars on hamburger menu - dont know why */
  /* found here: https://stackoverflow.com/questions/36531708/why-does-position-absolute-make-page-to-overflow */
  overflow-x:hidden;
  overflow-y:hidden;
 /* added that only to hide scroll bars on hamburger menu - dont know why */
}
body {
   background: white;
   /*overflow: hidden;*/
}

button {
  height: 50px;
  width: 140px;
}

.pass-input {
  color: orange;
  font-size: 24px;
  margin: 10px;
  padding: 5px;
  background-color: darkslategray;
}

#auth-but {
  margin: 10px;
  background-color: transparent;
  cursor: pointer;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

textarea {
  width: 100%;
  height: 100%; 
  resize: none;
  border: 0px solid #ccc;
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  background-color: transparent;
  color: inherit;
}

.textarea-container {
  height: 90%; /* this might determine the height of nav-bar-container */
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: -30px;
}

.hidden {
  display : none !important;
}

.blued {
  color: blue; 
}

textarea:focus {
  outline: none !important;
  border:0px solid red;
}

.circle {
  width: 7px;
  height: 7px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.savingTextStyle {
  font-family: "Arial";
  font-size: 16px;
  width: auto;
  display: inline-block;
 
}

.label-white {
  color: white;
}

.label-black {
  color: black;
}
.menu-label {
  font-family: "Inter custom", sans-serif;
  font-size: 16px;
}

.opener-tbl {
    color:black;
    font-family: Arial;
    font-weight: bolder;
}
.btn {
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 4px;
  font-family: "Inter custom", sans-serif;
  border: 0px solid red;
  background: blue;
  font-size: 16px;
  color: white;
}

.btn-grayish {
  background: gray;
  color: white;
}

.btn-framed {
  background: white;
  color: black;
  border: 1px solid gray;
}

.btn-framed-100 {
  background: white;
  color: black;
  border: 1px solid gray;
  width: 100%;
  display: block;
}

.light-mode-dark {
  background-color: rgb(51,51,51);
  color: white;
}

.light-mode-light {
  background-color: white;
  color: black;
}

.contentEditablo {
    background-color: orange;
    height: 100px;
    cursor: text;
    caret-color: black;
    font-weight: 400;
    font-stretch: 100%;
    overflow: hidden;
}

.circleo {
  
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid gray;
  float: left;
  margin-right: 6px;
  border-radius: 50%; 
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.4674f8de.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIconsOutlined-Regular'),
    url(/static/media/MaterialIcons-Regular.cff684e5.woff2) format('woff2'),
    url(/static/media/MaterialIcons-Regular.83bebaf3.woff) format('woff'),
    url(/static/media/MaterialIcons-Regular.f20305de.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Outline';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIconsOutlined-Regular.f5ef2424.otf) format('opentype');
}

.edit-parent-div {
  text-align: center;

}

.edit-child-div {
  display: inline-block;
}

.material-icons-outlined, .material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

.material-icons-outlined {
  font-family: 'Material Icons Outline';
}



/* list.css */
.list-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  padding: 20px;
}

/* nav.css */

/* .nav-bar {
  
  width: 100%;
  border-bottom: 2px solid lightgray;
  background-color: #ecf0f1;

} */

.nav-bar-dark {
  /* position:absolute; */
  width: 100%;
  border-bottom: 2px solid white;
  background-color: rgb(51,51,51);
}

.nav-bar-light {
  /*position:absolute;*/
  width: 100%;
  border-bottom: 2px solid lightgray;
  background-color: #ecf0f1;
}
.nav-container {
  display: table;
  /* border-collapse: separate; */
  border-spacing: 0px;

  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  /* padding: 10px; */
  /* padding-top: 3px;
  padding-bottom: 5px; */
  border-collapse: collapse;
  font-family: 'Assistant', sans-serif;
  letter-spacing: 0.6px;
  
}

.nav-row { display: table-row; border: 0px;}

.nav-list {
  /* font-size: 1em; */
  /*float: left;*/ /*cant use float with display: table-ce; */
  cursor: pointer;
  font-weight: normal;
  border: 0px solid black;
  /* margin-right: 20px; */
  display:table-cell;
  vertical-align: middle;
  padding: 10px;
  /* padding cause problem during minimizing screen size !!! */
  /* padding: 5px; */
  /* padding-right: 25px; */
  
}

/* Used only in Opener */
.nav-list-narrow {
  font-size: 1em;
  /*float: left;*/ /*cant use float with display: table-ce; */
  cursor: pointer;
  font-weight: normal;
  border: 0px solid black;
  margin-right: 20px;
  display:table-cell;
  vertical-align: middle;
  padding: 5px;
  padding-right: 5px;
  
}

.nav-span {
  display:block;
  bottom:0;
  left: 0;
}

.info {
  cursor: default;
}

.nav-span-blue {
  display:block;
  bottom:0;
  left: 0;
  color: blue;
}

.nav-icon-col {
  padding-left: 3px;
  padding-right: 3px;
}

@-webkit-keyframes move {
    0%   {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
    25%  {-webkit-transform: rotate(10deg);transform: rotate(10deg);}
    50%  {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
    75%  {-webkit-transform: rotate(10deg);transform: rotate(10deg);}
    100% {margin-left: 0; -webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
}

@keyframes move {
    0%   {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
    25%  {-webkit-transform: rotate(10deg);transform: rotate(10deg);}
    50%  {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
    75%  {-webkit-transform: rotate(10deg);transform: rotate(10deg);}
    100% {margin-left: 0; -webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
}





.nav-list-right {
  color: black;
  font-size: 1em;
  cursor: pointer;
  font-weight: normal;
  border: 0px solid black;
  margin-right: 20px;
  float: right;
  border: 0px solid red;
  margin-top: 0px;
}





/* @media only screen and (max-width: 500px) {
  .nav-container {
    height: 60px;
    background-color: #3498db;
    box-shadow: 0 10px 20px rgba(0,0,0,0.09), 0 6px 6px rgba(0,0,0,0.13);
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Assistant', sans-serif;
    letter-spacing: 0.6px;
  }


} */



/* @media only screen and (max-width: 500px) {



} */

/*note.css */

.note-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  position: relative;
  font-family: 'Assistant', sans-serif;
  width: 100%;
  height: auto;
}

.rootDiv {
  height: 100%;
}









.note-textarea {
  
  width: 500px;
  height: 200px;
  border: none;
  resize: none;
  border-radius: none;
  font-family: 'Assistant', sans-serif;
  font-size: 1.2em;
  transition: all 0.2s ease-in;

}

.note-textarea:focus {
  outline: none;
}


/* @media only screen and (max-width: 700px) {


  .note-textarea {
    width: 360px;
    height: 200px;
    border: none;
    resize: none;
    box-shadow: 0 3px 9px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24);
    padding: 30px;
    margin: 40px;
    margin-bottom: 10px;
    margin-top: 0;
    border-radius: none;
    font-family: 'Assistant', sans-serif;
    font-size: 1.1em;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }

 


  
} */

/* @media only screen and (max-width: 500px) {

 

  .note-textarea {
    width: 250px;
    height: 200px;
    border: none;
    resize: none;
    box-shadow: 0 3px 9px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24);
    padding: 30px;
    margin: 40px;
    margin-bottom: 10px;
    margin-top: 0;
    border-radius: none;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }




  
} */
